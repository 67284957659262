var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_main_widget_content_number_page bbox d-flex align-center",
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.content,
            expression: "content",
          },
        ],
        staticClass: "number bbox",
        class: { disabled: !_vm.editable },
        attrs: {
          type: "text",
          disabled: !_vm.editable,
          readonly: _vm.other_lock,
          placeholder: "输入数字",
        },
        domProps: { value: _vm.content },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.content = $event.target.value
            },
            function ($event) {
              return _vm.changeContent()
            },
          ],
          click: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("handleLock", _vm.item)
          },
          select: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("handleLock", _vm.item)
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.unit,
            expression: "unit",
          },
        ],
        staticClass: "unit bbox",
        class: { disabled: !_vm.editable },
        attrs: {
          type: "text",
          disabled: !_vm.editable,
          readonly: _vm.other_lock,
          placeholder: "输入单位",
        },
        domProps: { value: _vm.unit },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.unit = $event.target.value
            },
            function ($event) {
              return _vm.changeUnit()
            },
          ],
          click: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("handleLock", _vm.item)
          },
          select: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("handleLock", _vm.item)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }