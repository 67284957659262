var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_main_widget_title_fun_group_page fun_group flex-shrink-0",
    },
    [
      _vm.main_switch
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-end",
                trigger: "click",
                "visible-arrow": false,
                "popper-class":
                  "components_task_main_widget_title_fun_group_page_menu",
              },
              model: {
                value: _vm.switch_menu,
                callback: function ($$v) {
                  _vm.switch_menu = $$v
                },
                expression: "switch_menu",
              },
            },
            [
              _c(
                "div",
                { staticClass: "menu_group" },
                [
                  _vm.relevancy_status
                    ? [
                        _vm.can_relevancy && !_vm.item.islink
                          ? _c(
                              "div",
                              {
                                staticClass: "button",
                                on: { click: _vm.openRelevancy },
                              },
                              [_vm._v("\n          关联\n        ")]
                            )
                          : _vm._e(),
                        _vm.can_relevancy && _vm.item.islink
                          ? _c(
                              "div",
                              {
                                staticClass: "button",
                                on: { click: _vm.deleteRelevancy },
                              },
                              [_vm._v("\n          删除关联\n        ")]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  1
                    ? _c(
                        "div",
                        {
                          staticClass: "button",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.doCopy.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n        复制\n      ")]
                      )
                    : _vm._e(),
                  _vm.delete_right
                    ? _c(
                        "div",
                        { staticClass: "button", on: { click: _vm.doDelete } },
                        [_vm._v("\n        删除\n      ")]
                      )
                    : _vm._e(),
                  _vm.reminder_status && !_vm.task_config.view
                    ? _c(
                        "div",
                        {
                          staticClass: "button",
                          on: { click: _vm.setReminder },
                        },
                        [_vm._v("\n        设置提醒\n      ")]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _c("template", { slot: "reference" }, [
                _c("div", {
                  staticClass: "menu button",
                  on: { click: _vm.handleClick },
                }),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }