var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom-start",
        trigger: "click",
        "visible-arrow": false,
        "popper-class":
          "components_task_main_widget_content_part_time_type_page_popover",
        disabled: !_vm.editable,
        readonly: _vm.other_lock,
      },
      model: {
        value: _vm.switch_popover,
        callback: function ($$v) {
          _vm.switch_popover = $$v
        },
        expression: "switch_popover",
      },
    },
    [
      _c("div", { staticClass: "list" }, [
        _c(
          "div",
          {
            staticClass: "item bbox cp",
            class: { active: _vm.timetype === 1 },
            on: {
              click: function ($event) {
                return _vm.selectTimeType(1)
              },
            },
          },
          [_vm._v("日期+时间")]
        ),
        _c(
          "div",
          {
            staticClass: "item bbox cp",
            class: { active: _vm.timetype === 2 },
            on: {
              click: function ($event) {
                return _vm.selectTimeType(2)
              },
            },
          },
          [_vm._v("日期")]
        ),
        _c(
          "div",
          {
            staticClass: "item bbox cp",
            class: { active: _vm.timetype === 3 },
            on: {
              click: function ($event) {
                return _vm.selectTimeType(3)
              },
            },
          },
          [_vm._v("时间")]
        ),
      ]),
      _c("template", { slot: "reference" }, [
        _c(
          "div",
          {
            staticClass:
              "components_task_main_widget_content_part_time_type_page bbox cp d-flex align-center un_sel",
            class: { disabled: !_vm.editable },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleClick()
              },
            },
          },
          [
            _c("div", { staticClass: "icon" }),
            _c("div", { staticClass: "text" }, [_vm._v("选择")]),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }