<template>
  <div
    class="components_task_main_widget_content_file_page"
  > 
     <!--预览图片 -->
    <el-image
      ref="image"
      v-show="0"
      :src="preview_img_src"
      :preview-src-list="img_list"
      @click.native="setImgZindex();$api.stopPropagation($event)"
    ></el-image>
      <!-- :accept="$input_file_accept" -->
    <input
      type="file"
      ref="file"
      style="display:none;"
      multiple
      @change="fileChange"
    />
    <template
      v-for="(val,index) in item.fileList"
    >
      <file
        v-if="val.type != 1"
        class="file_wrapper"
        :key="index"
        :file="val"
        :editable="editable"
        :system="system"
        :shopping_mail="shopping_mail"
        @handleUpload="reUpload"
        @handleCancel="cancelUpload"
        @handleDelete="deleteFile"
        @handleSelect="selectFile"
        @handlePreview="previewFile"
        @handleDownload="downloadFile"
      ></file>
    </template>
  </div>
</template>
<script>
import File from './Part/File.vue';
import oss from '@/api/modules/oss'
import store from '@/store'
export default {
  components: {
    File,
  },
  props:{
    item:{
      type: Object,
      default:null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    other_lock: {
      type: Boolean,
      default: false,
    },
    self_lock: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Object,
      default: null,
    },
    system: {
      type: Boolean,
      default: false,
    },
    shopping_mail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    task () {
      return store.state.task
    },
    clearWidgetContent () {
      try {
        const { task } = this
        return task.task.clearWidgetContent
      } catch (e) {
        return false
      }
    }
  },
  data(){
    return {
      upload_list: [],
      custom_index: 0,
      img_list: [],
      preview_img_src: '',
    }
  },
  watch: {
    clearWidgetContent: function (val) {
      if (val) {
        this.$set(this.item, 'fileList', [])
      }
    }
  },
  mounted(){
   
  },
  methods: {
    touchAdd(){
      const {item} = this;
      console.log(item)
      const {fileList, uploading} = item;
      if(uploading){
        this.$notice({desc: '有文件正在上传, 请稍候再试!'});
        return;
      }
      if(!fileList){
        item.fileList = []
      }
      const filter_list = fileList.filter( unit => {
        return unit.type !== 1;
      })
      if(filter_list && filter_list.length == 20){
        this.$notice({desc: '最多只能上传20个附件!'});
        return;
      }
      setTimeout(()=>{
        this.$refs.file.click();
      },50)
    },
    fileChange(){
      const {item} = this;
      const {fileList} = item;
      const files = this.$refs.file.files;
      const filter_list = fileList.filter( unit => {
        return unit.type !== 1;
      })
      if(files && files.length && (filter_list.length + files.length > 20)){
        this.$notice({desc: `最多只能上传20个附件!还可以上传${20-fileList.length}个`});
        this.$refs.file.value = ""; // 清空列表
        return;
      }
      // 最先加的放在最下面
      let upload_list = [];
      for(let i = 0; i<files.length; i ++){
        const file = files[i];
        const filedata = {
          filename: file.name,
          filesize: parseInt(Math.ceil(file.size / 1024)), // 向上取整
          filetype: this.$tools.getRealExtName(file.name),
          file,
          custom_index: this.custom_index++,
          filesort: this.item.fileList.length + this.custom_index,
        }
        // console.log(filedata, filedata.filesort);
        if(filedata.filesize >= 500*1024){
          this.$notice({desc: '无法上传超过500M的文件'});
        }else{
          upload_list.unshift(filedata);
        }
      }
      this.$set(this.item, 'fileList', upload_list.concat(this.item.fileList));
      this.$refs.file.value = ""; // 清空图片列表
      this.autoUpload();
    },
    // 自动查找第一个可上传文件上传
    async autoUpload(){
      //标记控件修改
      this.$api.moduleTask.signWidgetModified(this.item);
      this.$set(this.item, 'uploading', true); // 正再上传
      const {item} = this;
      const {fileList} = item;
      const file = fileList.find(unit=>{
        return !unit.url && unit.status !== 'failed';
      })
      if(file !== undefined){
        let res = await this.upload(file);
        if(res){
          if( res === 'no_upload'){
                // this.$set(file, 'status', 'done');
            this.$set(file, 'url', res);
            this.$set(file, 'createTime', new Date().getTime());
            this.$set(file, 'status', 'no_upload');
          }else{

            // this.$set(file, 'status', 'done');
            this.$set(file, 'url', res);
            this.$set(file, 'createTime', new Date().getTime());
            this.$set(file, 'status', '');
          }
        }else{
          this.$set(file, 'status', 'failed');
        }
        this.autoUpload();
        this.modifiedSign()
      }else{
        this.$set(this.item, 'uploading', false); // 正再上传 结束
        return;
      }

    },
    async upload(item){
      const {system} = this;
      if(system){
        return 'no_upload'
      }

      let res;
      const handleProgress = (progress)=>{
        this.$set(item, 'progress', Math.floor(progress*100));
      }
      const {file} = item;
      const {filetype} = item;
      res = await this.$api.oss.uploadFile({
        file,
        handleProgress
      })
      if(res !== false){
        return res;
      }
      return res;
      // const res = await this.$upload.upload
    },
    async reUpload(file){
      const {item} = this;
      const {uploading} = item;
      if(uploading){
        this.$notice({desc: '有文件正在上传, 请稍候再试!'});
        return;
      }
      
      this.$set(this.item, 'uploading', true); // 正再上传
      this.$set(file, 'status', '');
      let res = await this.upload(file);
      if(res){
        // this.$set(file, 'status', 'done');
        this.$set(file, 'url', res);
        this.$set(file, 'createTime', new Date().getTime());
        this.$set(file, 'status', '');
      }else{
        this.$set(file, 'status', 'failed');
      }
      this.$set(this.item, 'uploading', false); // 正再上传 结束

    },
    cancelUpload(file){
      file.cancelSource.cancel(false);
    },
    async getLock(){
      const {self_lock} = this;
      if(self_lock){
        return true;
      }else{
        const {task_config} = this.$store.state;
        const {edit} = task_config;
        const {item} = this;
        const {id} = item;
        if(edit){
          if(id){
            const res = await this.$api.moduleTask.getLock(item);
            if(!res){
              return false;
            }
          }
        }
        return true;
       
      }
    },
    async deleteFile(file){
      const lock = await this.getLock();
      if(!lock){
        return;
      }
      
      //标记控件修改
      this.$api.moduleTask.signWidgetModified(this.item);
      const {id} = file;
      if(id){
        this.$set(file, 'type', 1);
      }else{
        const index = this.item.fileList.findIndex(unit=>{
          return unit.custom_index == file.custom_index;
        })
        if(index != -1){
          this.item.fileList.splice(index,1);
        }
      }
      this.modifiedSign();

    },
    selectFile(file){
      const {select} = file;
      if(select){
        this.$set(file, 'select', false);
      }else{
        this.$set(file, 'select', true);
      }
    },
    async downloadFile(file){
      oss.downloadFile({file})
      return
      let {id, filetype, createTime} = file;
      let type = 2;
      if(id){
        let exportURL = `https://${this.$domain_name}/project/download?fileid=${id}&type=${type}`;
        // iframe下载
        const iframe = document.createElement("iframe");
        iframe.style.display = "none"; // 防止影响页面
        iframe.style.height = 0; // 防止影响页面
        iframe.src = exportURL; 
        document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
        // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
        setTimeout(()=>{
          iframe.remove();
        }, 5 * 60 * 1000);
      }
    },
    selectAll(){
      const {item} = this;
      const {fileList} = item;
      fileList.forEach(unit=>{
        if(!unit.select){
          this.$set(unit, 'select', true);
        }
      })
    },
    cancelAll(){
      const {item} = this;
      const {fileList} = item;
      fileList.forEach(unit=>{
        if(unit.select){
          this.$set(unit, 'select', false);
        }
      })
    },
    async multiDownload(){
      const {item} = this;
      const {fileList} = item;
      for(let i in fileList){
        if(fileList[i].select){
          await this.downloadFile(fileList[i]);
        }
      }
    },
    async multiDelete(){
      const {item} = this;
      const {fileList} = item;
      const file = fileList.find(unit=>{
        return unit.select && unit.type != 1;
      })
      if(file !== undefined){
        await this.deleteFile(file);
        this.multiDelete();// 继续删除
      }
    },
    setImgZindex(){
      const zIndex = this.$utils.getZIndex();
      document.querySelectorAll('.el-image-viewer__wrapper').forEach(item=>{
        item.style.zIndex = zIndex;
      })
    },
    previewFile(file){
      // console.log(file);
      const {filename,url} = file;
      const filetype = this.$tools.getExtName(filename);
      if(filetype === 'mp4'){
        this.previewVideo(url);
      }
      if(filetype === 'pic' || filetype === 'jpg' || filetype === 'jpeg' || filetype === 'png'){
        this.previewPicture(url);
      }
      if(filetype === 'doc' || filetype === 'ppt' || filetype === 'xls' || filetype === 'docx' || filetype === 'pptx' || filetype === 'xlsx'){
        this.previewOffice(url);
      }
      if(filetype === 'pdf'){
        this.previewPdf(url);
      }
    },
    previewVideo(url){
      let protocol = location.protocol;
      let src = this.$file_url;
      // 根据当前环境切换http/https源
      if(protocol == 'http:'){
        src = this.$file_url_http;
      }
      src += url;
      this.$openVideo({src})
    },
    previewPicture(url){
      const {item} = this;
      const {fileList} = item;
      const critical = this.$critical;
      const picture_critical = this.$picture_critical;

      let img_list = [];
      fileList.forEach(unit=>{
        if(this.$tools.getExtName(unit.filename) === 'pic' && unit.url){
            img_list.push(`${this.$file_url}${unit.url}`);
        }
      })
      this.$set(this, 'img_list', img_list);

      this.preview_img_src = `${this.$file_url}${url}`;
      this.$nextTick(function(){
        this.$refs.image.clickHandler();
        this.setImgZindex();
      })
    },
    previewOffice(url){
      const basic_url = 'https://view.officeapps.live.com/op/view.aspx?src=';
      window.open(`${basic_url}${this.$file_url}${url}`, '_blank')
    },
    previewPdf(url){
      window.open(`${this.$file_url}${url}`)
    },
    modifiedSign(){
      this.$emit('handleMofifiedSign', {item: this.item, parent: this.parent});
    },
  },
  

  /**
   * 上传
   * 
   * 
   * 上传时 控件增加属性 is_loading 结束时删除
upload_config{
  number: 总数量
  current: 当前索引
}
fileList
{
  filename,
  filesize,
  filetype,
  url,
  // 自定义
  new_file,是否新添加
  progress, 上传进度
  status, 状态
  select,
}
   */
  /**
   * 


{

  projectTask/createTask需要的附件参数fileJson[{}]
  filename,
  filesize,
  filetype,
  url,
  type,
}
   */
}
</script>

<style lang="scss" scoped>
.components_task_main_widget_content_file_page{
  .file_wrapper{
     &:hover{
      background-color:#eee;
    }
  }
}
</style>