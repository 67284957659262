var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_main_widget_title_page d-flex align-center justify-space-between bbox",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "title_group d-flex align-center justify-space-between bbox",
        },
        [
          _c("div", { staticClass: "info_group d-flex align-center" }, [
            _c("div", {
              staticClass: "icon",
              class: [_vm.getWidgetType(_vm.item.type)],
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.remark,
                  expression: "remark",
                },
              ],
              staticClass: "title",
              class: { disabled: !_vm.editable },
              attrs: {
                type: "text",
                placeholder: "输入标题",
                disabled: !_vm.editable,
                readonly: _vm.other_lock,
              },
              domProps: { value: _vm.remark },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.remark = $event.target.value
                  },
                  function ($event) {
                    return _vm.changeRemark()
                  },
                ],
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.touchTitle()
                },
                select: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("handleLock", _vm.item)
                },
              },
            }),
          ]),
          _vm.other_group && !_vm.task_config.view && !_vm.printMode
            ? _c(
                "div",
                {
                  staticClass: "other_group flex-shrink-0 d-flex align-center",
                  class: { uneditable: !_vm.editable },
                },
                [
                  _vm.downloadable
                    ? _c("div", {
                        staticClass: "button download",
                        on: {
                          click: function ($event) {
                            return _vm.multiDownload()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.deleteable && _vm.editable && !_vm.other_lock
                    ? _c("div", {
                        staticClass: "button delete",
                        on: {
                          click: function ($event) {
                            return _vm.multiDelete()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.sortable && _vm.editable && !_vm.other_lock
                    ? _c("div", {
                        staticClass: "button sort",
                        on: {
                          click: function ($event) {
                            return _vm.touchSort()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("div", {
                        staticClass: "button add",
                        on: {
                          click: function ($event) {
                            return _vm.touchAdd()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.select
                    ? _c("div", {
                        staticClass: "button select",
                        class: { on: _vm.is_all },
                        on: {
                          click: function ($event) {
                            return _vm.touchSelectALL()
                          },
                        },
                      })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm.editable && (!_vm.task_config.view || _vm.task_config.reEditTeach)
        ? _c("fun-group", {
            attrs: {
              item: _vm.item,
              relevancy: _vm.relevancy,
              public_notice: _vm.public_notice,
              system: _vm.system,
            },
            on: {
              handleDelete: _vm.doDelete,
              handleDeleteRelevancy: _vm.deleteRelevancy,
              handleSwitchReminder: _vm.handleSwitchReminder,
            },
          })
        : _vm._e(),
      _vm.switch_file_sorting ? _c("file-sorting") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }