
<template>
  <el-popover
    placement="bottom-start"
    trigger="click"
    :visible-arrow="false"
    popper-class="components_task_main_widget_content_part_time_type_page_popover"
    v-model="switch_popover"
    :disabled="!editable"
    :readonly="other_lock"
  >
    <div class="list">
      <div class="item bbox cp"
        :class="{active: timetype === 1}"
        @click="selectTimeType(1)"
      >日期+时间</div>
      <div class="item bbox cp"
        :class="{active: timetype === 2}"
         @click="selectTimeType(2)"
      >日期</div>
      <div class="item bbox cp"
        :class="{active: timetype === 3}"
         @click="selectTimeType(3)"
      >时间</div>
    </div>
    <template slot="reference">
      <div
        class="components_task_main_widget_content_part_time_type_page bbox cp d-flex align-center un_sel"
        :class="{disabled: !editable}"
        @click.stop="handleClick();"
      >
        <div class="icon"></div>
        <div class="text">选择</div>
      </div>
    </template>
  </el-popover>
</template>
<script>
export default {
  props:{
    item: {
      type: Object,
      default: null,
    },
    timetype: {
      type: Number,
      default: 1,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    other_lock: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      switch_popover: false,
    }
  },
  methods: {
    selectTimeType(item){
      this.$emit('handleChange', item);
      this.switch_popover = false;
    },
    async handleClick(){
      const {task_config} = this.$store.state;
      const {edit} = task_config;
      const {item} = this;
      const {id} = item;
      if(edit){
        if(id){
          const res = await this.$api.moduleTask.getLock(item);
          if(!res){
            this.switch_popover = false;
          }
        }
      }
      // this.$emit('handleLock', this.item);
    }
  }
}
</script>
<style lang="scss" scoped>
.components_task_main_widget_content_part_time_type_page{
  font-size: 14px;
  color: #777;
  height: 24px;
  border-right: 1px solid #f4f4f4;
  padding-right: 14px;
  &.disabled:hover{
    .text{
      transform: scaleX(0);
      width: 0;
    }
  }
  &:hover{
    .text{
      transform: scaleX(1);
      width: auto;
    }
  }
  .text{
    transform: scaleX(0);
    transform-origin: left center;
    width: 0;
    padding-left: 6px;
  }
  .icon{
    @include background(16px, 16px);
    width: 20px;
    height: 20px;
    background-image: url(~@/assets/images/task/time_type.png);
  }
}
</style>
<style lang="scss">
.components_task_main_widget_content_part_time_type_page_popover{
  padding: 5px 0;
  margin-top: 0px!important;
  .list{
    .item{
      @include transition;
      font-size: 14px;
      color: #333333;
      line-height: 34px;
      padding: 0 10px;
      background-color: #eee;
      &.active{
        font-weight: bold;
      }
      &:hover{
        background-color: #ddd;
      }
    }
  }
}
</style>