var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_task_main_widget_content_print_mode_picture bbox" },
    [
      _c(
        "el-image",
        {
          key: _vm.resizeKey,
          ref: "image",
          staticClass: "image",
          attrs: { src: `${_vm.$img_url}${_vm.file.url}` },
        },
        [
          _c(
            "div",
            {
              staticClass: "image-slot",
              attrs: { slot: "placeholder" },
              slot: "placeholder",
            },
            [
              _c("img", {
                staticClass: "img_placeholder",
                attrs: { src: `${_vm.$img_url}${_vm.file.url}` },
              }),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "filename" }, [
        _vm._v("\n    " + _vm._s(_vm.file.filename) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }