<template>
  <div
    class="components_task_main_widget_title_page d-flex align-center justify-space-between bbox"
  >
    <div
      class="title_group d-flex align-center justify-space-between bbox"
    >
      <div
        class="info_group d-flex align-center"
      >
        <div
          class="icon"
          :class="[getWidgetType(item.type)]"
        ></div>
        <input
          type="text"
          v-model="remark"
          class="title"
          :class="{disabled: !editable}"
          placeholder="输入标题"
          :disabled="!editable"
          :readonly="other_lock"
          @input="changeRemark();"
          @click.stop="touchTitle()"
          @select.stop="$emit('handleLock', item)"
        >
      </div>
      <div
        v-if="other_group && !task_config.view && !printMode"
        class="other_group flex-shrink-0 d-flex align-center"
        :class="{uneditable:!editable}"
      >
        <div
          v-if="downloadable"
          class="button download"
          @click="multiDownload()"
        ></div>
        <div
          v-if="deleteable && editable && !other_lock"
          class="button delete"
          @click="multiDelete()"
        ></div>
        <div
          v-if="sortable && editable && !other_lock"
          class="button sort"
          @click="touchSort()"
        ></div>
        <div
          v-if="editable"
          class="button add"
          @click="touchAdd()"
        ></div>
        <div
          v-if="select"
          class="button select"
          :class="{on: is_all}"
          @click="touchSelectALL()"
        ></div>
      </div>
    </div>
    <fun-group
      v-if="editable && (!task_config.view || task_config.reEditTeach)"
      :item="item"
      :relevancy="relevancy"
      :public_notice="public_notice"
      :system="system"
      @handleDelete="doDelete"
      @handleDeleteRelevancy="deleteRelevancy"
      @handleSwitchReminder="handleSwitchReminder"
    ></fun-group>
    <file-sorting
      v-if="switch_file_sorting"
    ></file-sorting>
  </div>
</template>

<script>
import FunGroup from './Title/FunGroup.vue';
import FileSorting from '@/components/PopUp/FileSorting/index.vue';
import store from '@/store'
const widget_arr = {
  1: 'text',
  2: 'number',
  3: 'money',
  4: 'time',
  6: 'file',
  7: 'hyperlink',
  9: 'picture',
  10: 'contact',
  11: 'wisdom',
}
export default {
  components:{
    FunGroup,
    FileSorting,
  },
  data(){
    return {
      remark: '',
      switch_file_sorting: false,
    }
  },
  inject: [
    'printMode'
  ],
  props: {
    item: {
      type: Object,
      default: null,
    },
    relevancy: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    other_lock: {
      type: Boolean,
      default: false,
    },
    self_lock: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Object,
      default: null,
    },
    public_notice: {
      type: Boolean,
      default: null,
    },
    system: {
      type: Boolean,
      default: false,
    },
    shopping_mail: {
      type: Boolean,
      default: false,
    },
    projection_screen_start: {
      type: Boolean,
      default: false,
    },
    
  },
  computed:{
    task () {
      return store.state.task
    },
    clearWidgetTitle () {
      try {
        const { task } = this
        return task.task.clearWidgetTitle
      } catch (e) {
        return false
      }
    },
    task_config(){
      return this.$store.state.task_config
    },
    other_group(){
      const {item,shopping_mail} = this;
      const {type} = item;
      if(shopping_mail){
        return false;
      }
      if(type === 6 || type ===9 || type == 7 || type == 10){
        return true;
      }
      return false;
    },
    select(){
      const {item} = this;
      const {fileList} = item;
      if(fileList && fileList.length){
        return true;
      }
      return false;
    },
    deleteable(){
      const {item} = this;
      const {fileList} = item;
      let deleteable = false;
      if(!fileList){
        return false;
      }
      fileList.forEach(unit=>{
        if(unit.select){
          deleteable = true;
        }
      })
      return deleteable;
    },
    sortable(){
      // return true;
      // let sortable = false;
      const { item } = this
      const { fileList, type } = item
      if (!fileList) {
        return false
      }
      if (type !== 6) {
        return false
      }
      if (!fileList.length) {
        return false
      }
      let useful_list = []
      fileList.forEach((item) => {
        if (item.type !== 1) {
          useful_list.push(item)
        }
      })
      if (!useful_list.length || useful_list.length < 2) {
        return false
      }
      return true
    },
    downloadable(){
      const {item, system} = this;
      if(system){
        return false;
      }
      const {fileList} = item;
      let downloadable = false;
      if(!fileList){
        return false;
      }
      fileList.forEach(unit=>{
        if(unit.select){
          downloadable = true;
        }
      })
      return downloadable;
    },
    is_all(){
      const {item} = this;
      const {fileList} = item;
      let is_all = true;
      fileList.forEach(unit=>{
        if(!unit.select){
          is_all = false;
        }
      })
      return is_all;
    },
    readonly_for_wisdom(){
      const user_id = Number(this.$tools.getUserId());
      const {item} = this;
      const {type, id, userId} = item;
     
      if(type == 11){
        if(userId){
          return !(Number(userId) === user_id);
        }
      }
      return false;
    }
  },
  watch:{
    clearWidgetTitle: function (val) {
      console.log(val)
      if (val) {
        this.remark = ''
      }
    },
    // 'item.remark': {
    //   handler: function(val){
    //     this.init();
    //   },
    //   deep: true,
    // },
    // 'item.remark': {
    //   handler: function (val) {
    //     this.remark = val
    //   },
    //   deep: true
    // },
    'item.repeal_title': {
      handler: function(val){
        if(val){
          this.init();
          this.$set(this.item, 'repeal_title', false);
        }
      },
      deep: true,
    },
    'item.cf_edit_data': {
      handler: function(val){
        if(val === undefined){
          this.init();
          
        }
      },
      deep: true,
    }
  },
  methods: {
    getWidgetType(type){
      if(widget_arr[type]){
        return widget_arr[type]
      }
      return 'text';
    },
    changeRemark(){
      this.$set(this.item, 'remark', this.remark);
      this.$api.moduleTask.signWidgetModified(this.item);
      this.modifiedSign();
    },
    async touchAdd() {
      const { self_lock } = this
      if (self_lock) {
        this.$emit('handleAdd')
      } else {
        const { task_config } = this.$store.state
        const { edit } = task_config
        const { item } = this
        const { id } = item
        if (edit) {
          if (id) {
            const res = await this.$api.moduleTask.getLock(item)
            if (!res) {
              return
            }
          }
        }
        this.$emit('handleAdd')
      }
    },
    async touchSort(){
      const {self_lock, item} = this;
      const {uploading} = item;
      if(uploading){
        this.$notice({desc: '文件未全部上传完成，暂不可修改排序'});
        return;
      }
      if(self_lock){
        this.openFileSorting();
      }else{
        const {task_config} = this.$store.state;
        const {edit} = task_config;
        const {item} = this;
        const {id} = item;
        if(edit){
          if(id){
            const res = await this.$api.moduleTask.getLock(item);
            if(!res){
              return;
            }
          }
        }
        this.openFileSorting();
      }
    },
    openFileSorting(){
      const params = {
        parent: this.parent,
        widget: this.item,
      }
      this.$emit("handleSortFile", params);
      // this.switch_file_sorting = true;
    },
    closeFileSorting(){
      this.switch_file_sorting = false;
    },
    touchSelectALL(){
      const {is_all} = this;
      // console.log(is_all)
      if(is_all){
        this.$emit('handleCancelAll');
      }else{
        this.$emit('handleSelectAll');
      }
    },
    multiDelete(){
      this.$emit('handleMultiDelete');
    },
    multiDownload(){
      this.$emit('handleMultiDownload');
    },
    init(){
      const {item} = this;
      const {cf_edit_data} = item;
      this.remark = item.remark;
      if(cf_edit_data){
        if(cf_edit_data.remark){
          this.remark = cf_edit_data;
        }
      }
    },
    doDelete(data){
      this.$emit('handleDelete', { widget:data, parent:this.parent });
    },
    deleteRelevancy(data){
      this.$emit('handleDeleteRelevancy', data);
    },
    modifiedSign(){
      this.$emit('handleMofifiedSign', {item: this.item, parent: this.parent});
    },
    handleSwitchReminder(data){
      this.$emit('handleSwitchReminder', data);
    },
    touchTitle(){
      const {projection_screen_start, item} = this;
      const {userId, type} = item;
      const user_id = Number(this.$tools.getUserId());
      // if(type == 11){
      //   if(userId && Number(userId) !== user_id){
      //     this.$notice({
      //       desc: '该控件只有创建者可以编辑',
      //     })
      //     return;
      //   }
      // }
      if(projection_screen_start){
        this.$notice({
          desc: '正在投屏中,请先结束投屏',
        })
        return;
      }
      this.$emit('handleLock', this.item);
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style lang="scss" scoped>
@mixin icon($type){
  &.#{$type}{
    background-image: url(~@/assets/images/task/#{$type}.png);
  }
}
@mixin button_icon($type){
  &.#{$type}{
    background-image: url(~@/assets/images/task/#{$type}_button.png);
  }
}
.components_task_main_widget_title_page{
  width: 100%;
  .title_group{
    flex: 1;
    height: 47px;
    border-bottom: 2px solid #f4f4f4;
    padding: {
      left: 10px;
    }
    .info_group{
      flex: 1;
      .icon{
        @include background(18px, 18px);
        width: 20px;
        height: 20px;
        margin-right: 10px;
        @include icon(text);
        @include icon(number);
        @include icon(money);
        @include icon(time);
        @include icon(file);
        @include icon(hyperlink);
        @include icon(picture);
        @include icon(contact);
        @include icon(wisdom);
      }
      .title{
        @include placeholder(#ccc);
        flex: 1;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 15px;
        font-weight: bold;
        color: #333;
      }
    }
    .other_group{
      &.uneditable{
        padding-right: 30px;
      }
      .button{
        @include background(16px,16px);
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-left: 10px;
        @include button_icon(download);
        @include button_icon(delete);
        @include button_icon(add);
        @include button_icon(select);
        @include button_icon(sort);
        &:first-child{
          margin-left: 10px;
        }
        &.select.on{
          background-image: url(~@/assets/images/task/select_button_on.png);
        }      
      }
    }

  }
  
}
</style>