var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      class: { disabled: !_vm.editable || _vm.other_lock },
      attrs: {
        placement: "bottom-start",
        trigger: "click",
        "visible-arrow": false,
        "popper-class":
          "components_task_main_widget_content_part_monetary_unit_page_popover",
        disabled: !_vm.editable || _vm.other_lock,
      },
      model: {
        value: _vm.switch_popover,
        callback: function ($$v) {
          _vm.switch_popover = $$v
        },
        expression: "switch_popover",
      },
    },
    [
      _c("div", { staticClass: "list" }, [
        _c(
          "div",
          {
            staticClass: "item bbox cp",
            class: { active: _vm.unit === "元" },
            on: {
              click: function ($event) {
                return _vm.selectUnit("元")
              },
            },
          },
          [_vm._v("元")]
        ),
        _c(
          "div",
          {
            staticClass: "item bbox cp",
            class: { active: _vm.unit === "美元" },
            on: {
              click: function ($event) {
                return _vm.selectUnit("美元")
              },
            },
          },
          [_vm._v("美元")]
        ),
      ]),
      _c("template", { slot: "reference" }, [
        _c(
          "div",
          {
            staticClass:
              "components_task_main_widget_content_part_monetary_unit_page bbox cp d-flex align-center un_sel",
            class: { disabled: !_vm.editable },
            on: {
              click: function ($event) {
                return _vm.handleClick()
              },
            },
          },
          [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.unit))]),
            _c("div", { staticClass: "icon" }),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }