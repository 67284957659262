var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "components_task_main_widget_content_contact_page bbox",
      class: { padding_none: !(_vm.item.userList && _vm.item.userList.length) },
    },
    [
      _c(
        "div",
        { staticClass: "list d-flex align-center flex-wrap" },
        [
          _vm._l(_vm.item.userList, function (val) {
            return [
              _c(
                "div",
                { key: val.userId, staticClass: "user flex-shrink-0" },
                [
                  _vm.editable && !_vm.other_lock
                    ? _c("div", {
                        staticClass: "close cp",
                        on: {
                          click: function ($event) {
                            return _vm.deleteUser(val)
                          },
                        },
                      })
                    : _vm._e(),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: val.friendRemark || val.userName,
                        placement: "bottom",
                        "open-delay": 500,
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "avatar cp",
                        attrs: {
                          src: `${_vm.$avatar_url}${val.thumbnail}@!small200`,
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openUserDetail(val)
                          },
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }