var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_task_main_widget_content_part_screen d-flex align-center justify-start",
      class: { warn: _vm.warnScreen, active: _vm.status_projection_screen },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.touchSingleScreen(_vm.unit)
        },
      },
    },
    [
      _c("div", { staticClass: "status mr-2" }),
      _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.unit.screenName))]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_symbol,
              expression: "show_symbol",
            },
          ],
          staticClass: "symbol",
        },
        [_vm._v(",")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }