var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_task_main_widget_content_file_page" },
    [
      _c("el-image", {
        directives: [
          { name: "show", rawName: "v-show", value: 0, expression: "0" },
        ],
        ref: "image",
        attrs: { src: _vm.preview_img_src, "preview-src-list": _vm.img_list },
        nativeOn: {
          click: function ($event) {
            _vm.setImgZindex()
            _vm.$api.stopPropagation($event)
          },
        },
      }),
      _c("input", {
        ref: "file",
        staticStyle: { display: "none" },
        attrs: { type: "file", multiple: "" },
        on: { change: _vm.fileChange },
      }),
      _vm._l(_vm.item.fileList, function (val, index) {
        return [
          val.type != 1
            ? _c("file", {
                key: index,
                staticClass: "file_wrapper",
                attrs: {
                  file: val,
                  editable: _vm.editable,
                  system: _vm.system,
                  shopping_mail: _vm.shopping_mail,
                },
                on: {
                  handleUpload: _vm.reUpload,
                  handleCancel: _vm.cancelUpload,
                  handleDelete: _vm.deleteFile,
                  handleSelect: _vm.selectFile,
                  handlePreview: _vm.previewFile,
                  handleDownload: _vm.downloadFile,
                },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }