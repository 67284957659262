<template>
  <div 
    class="components_task_main_widget_content_time_page d-flex align-center bbox"
  >
    <time-type
      @handleChange="changeTimeType"
      :timetype="item.timetype"
      :editable="editable"
      :other_lock="other_lock"
      :item="item"
    ></time-type>
    <time-for-date
      v-if="item.timetype != 3"
      :content="String(item.content)"
      @handleChange="changeDate"
      :editable="editable"
      :other_lock="other_lock"
      :item="item"
    ></time-for-date>
    <time-for-time
      v-if="item.timetype != 2"
      :content="String(item.content)"
      @handleChange="changeTime"
      :editable="editable"
      :other_lock="other_lock"
      :item="item"
    ></time-for-time>
  </div>
</template>
<script>
import TimeType from './Part/TimeType.vue';
import TimeForDate from './Part/TimeForDate.vue';
import TimeForTime from './Part/TimeForTime.vue';
import store from '@/store'
export default {
  components: {
    TimeType,
    TimeForDate,
    TimeForTime,
  },
  props:{
    item:{
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    },
    other_lock: {
      type: Boolean,
      default: false
    },
    parent: {
      type: Object,
      default: null
    }
  },
  computed: {
    task () {
      return store.state.task
    },
    clearWidgetContent () {
      try {
        const { task } = this
        return task.task.clearWidgetContent
      } catch (e) {
        return false
      }
    }
  },
  data(){
    return{
      date_data: '',
      time_data: '',
    }
  },
  watch: {
    clearWidgetContent: function (val) {
      if (val) {
        this.date_data = ''
        this.time_data = ''
        this.generateContent()
      }
    }
  },
  methods: {
    changeTimeType(type) {
      console.log(type)
      this.$set(this.item, 'timetype', type)
      this.$api.moduleTask.signWidgetModified(this.item)
      this.generateContent()
      this.modifiedSign()
    },
    changeDate(date) {
      this.$set(this, 'date_data', date)
      this.generateContent()
      this.modifiedSign()
    },
    changeTime(time) {
      this.$set(this, 'time_data', time)
      this.generateContent()
      this.modifiedSign()
    },
    generateContent() {
      const { date_data, time_data, item} = this
      const { timetype } = item
      const { year, month, date } = date_data || {}
      const { hour, minute } = time_data || {}
      const data = {
        year: year === undefined ? new Date().getFullYear() : year,
        month: month === undefined ? new Date().getMonth() : month,
        date: date === undefined ? new Date().getDate() : date,
        // hour: hour === undefined ? new Date().getHours() : hour,
        // minute: minute === undefined ? new Date().getMinutes() : minute,
        hour: hour === undefined ? 0 : hour,
        minute: minute === undefined ? 0 : minute
      }
  
      // console.log(date_data, time_data);
      let content = new Date(data.year,data.month, data.date, data.hour, data.minute).getTime();
      if(year === undefined && month === undefined && date === undefined && hour === undefined && minute === undefined){
        content = '';
      }
      if(timetype === 2 && year === undefined && month === undefined && date === undefined){
        content = ''
      }
      if(timetype === 3 && hour === undefined && minute === undefined){
        content = ''
      }
      console.log(content)
      this.$set(this.item, 'content', content);
      this.$api.moduleTask.signWidgetModified(this.item);
      this.modifiedSign();
    },
    modifiedSign(){
      this.$emit('handleMofifiedSign', {item: this.item, parent: this.parent});
    },

  },
  mounted(){
    const { item } = this
    const { content, timetype } = item
    // console.log(new Date(Number(content)));
    if (content) {
      const time = new Date(Number(content))
      const year = time.getFullYear()
      const month = time.getMonth()
      const date = time.getDate()
      const hour = time.getHours()
      const minute = time.getMinutes()
      // console.log(year, month, date, hour, minute)
      this.$set(this,'date_data', {year, month, date})
      this.$set(this,'time_data', { hour, minute})
    }

  }
}
</script>
<style lang="scss" scoped>
.components_task_main_widget_content_time_page{
  height: 47px;
  padding: 0 10px;
}
</style>
<style lang="css">
/* 时间选择样式修改  */
.el-date-table td span{
  border-radius: 0;
}
.el-input__icon {
  line-height: unset;
}
.el-input__inner{
  height: auto;
  cursor: pointer;
}
.el-input.is-disabled .el-input__inner{
  background-color: transparent;
  color: #333;
}

</style>