var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "components_task_main_widget_wrapper_page bbox",
      style: { "z-index": _vm.index },
    },
    [
      _c(
        "div",
        {
          staticClass: "contents bbox",
          class: { relevancy: _vm.relevancy, heightlight: _vm.heightlight },
          style: { "z-index": _vm.index },
        },
        [
          _c("widget-title", {
            attrs: {
              item: _vm.item,
              relevancy: _vm.relevancy,
              editable: _vm.editable,
              self_lock: _vm.self_lock,
              other_lock: _vm.other_lock,
              parent: _vm.parent,
              system: _vm.system,
              public_notice: _vm.public_notice,
              shopping_mail: _vm.shopping_mail,
              projection_screen_start: _vm.projection_screen_start,
              editable_for_wisdom: _vm.projection_screen_status,
            },
            on: {
              handleAdd: _vm.touchAdd,
              handleSortFile: _vm.handleSortFile,
              handleCancelAll: _vm.cancelAll,
              handleSelectAll: _vm.selectAll,
              handleMultiDelete: _vm.multiDelete,
              handleMultiDownload: _vm.multiDownload,
              handleLock: _vm.handleLock,
              handleDelete: _vm.deleteWidget,
              handleDeleteRelevancy: _vm.deleteRelevancy,
              handleMofifiedSign: _vm.modifiedSign,
              handleSwitchReminder: _vm.handleSwitchReminder,
            },
          }),
          _vm.item && _vm.item.type && !_vm.taskFold
            ? [
                _vm.item.type === 1
                  ? _c("content-text", {
                      attrs: {
                        item: _vm.item,
                        parent: _vm.parent,
                        editable: _vm.editable,
                        other_lock: _vm.other_lock,
                      },
                      on: {
                        handleLock: _vm.handleLock,
                        handleMofifiedSign: _vm.modifiedSign,
                      },
                    })
                  : _vm._e(),
                _vm.item.type === 2
                  ? _c("content-number", {
                      attrs: {
                        item: _vm.item,
                        parent: _vm.parent,
                        editable: _vm.editable,
                        other_lock: _vm.other_lock,
                      },
                      on: {
                        handleLock: _vm.handleLock,
                        handleMofifiedSign: _vm.modifiedSign,
                      },
                    })
                  : _vm._e(),
                _vm.item.type === 3
                  ? _c("content-money", {
                      attrs: {
                        item: _vm.item,
                        parent: _vm.parent,
                        editable: _vm.editable,
                        other_lock: _vm.other_lock,
                      },
                      on: {
                        handleLock: _vm.handleLock,
                        handleMofifiedSign: _vm.modifiedSign,
                      },
                    })
                  : _vm._e(),
                _vm.item.type === 4
                  ? _c("content-time", {
                      attrs: {
                        item: _vm.item,
                        parent: _vm.parent,
                        editable: _vm.editable,
                        other_lock: _vm.other_lock,
                      },
                      on: {
                        handleLock: _vm.handleLock,
                        handleMofifiedSign: _vm.modifiedSign,
                      },
                    })
                  : _vm._e(),
                _vm.item.type === 6
                  ? _c("content-file", {
                      ref: "file",
                      attrs: {
                        item: _vm.item,
                        parent: _vm.parent,
                        editable: _vm.editable,
                        self_lock: _vm.self_lock,
                        other_lock: _vm.other_lock,
                        system: _vm.system,
                        shopping_mail: _vm.shopping_mail,
                      },
                      on: {
                        handleLock: _vm.handleLock,
                        handleMofifiedSign: _vm.modifiedSign,
                      },
                    })
                  : _vm._e(),
                _vm.item.type === 7
                  ? _c("content-hyperlink", {
                      ref: "hyperlink",
                      attrs: {
                        item: _vm.item,
                        parent: _vm.parent,
                        editable: _vm.editable,
                        other_lock: _vm.other_lock,
                      },
                      on: {
                        handleLock: _vm.handleLock,
                        handleMofifiedSign: _vm.modifiedSign,
                      },
                    })
                  : _vm._e(),
                _vm.item.type === 9
                  ? _c("content-picture", {
                      ref: "picture",
                      attrs: {
                        item: _vm.item,
                        parent: _vm.parent,
                        editable: _vm.editable,
                        self_lock: _vm.self_lock,
                        other_lock: _vm.other_lock,
                        system: _vm.system,
                      },
                      on: {
                        handleLock: _vm.handleLock,
                        handleMofifiedSign: _vm.modifiedSign,
                      },
                    })
                  : _vm._e(),
                _vm.item.type === 10
                  ? _c("content-contact", {
                      ref: "contact",
                      attrs: {
                        item: _vm.item,
                        parent: _vm.parent,
                        editable: _vm.editable,
                        other_lock: _vm.other_lock,
                        self_lock: _vm.self_lock,
                      },
                      on: {
                        handleLock: _vm.handleLock,
                        handleMofifiedSign: _vm.modifiedSign,
                        setZIndex: _vm.setZIndex,
                      },
                    })
                  : _vm._e(),
                _vm.item.type === 11
                  ? _c("content-wisdom", {
                      ref: "wisdom",
                      attrs: {
                        item: _vm.item,
                        parent: _vm.parent,
                        editable: _vm.editable,
                        other_lock: _vm.other_lock,
                        self_lock: _vm.self_lock,
                        status_projection_screen: _vm.projection_screen_start,
                      },
                      on: {
                        handleLock: _vm.handleLock,
                        handleMofifiedSign: _vm.modifiedSign,
                        setZIndex: _vm.setZIndex,
                      },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          !_vm.relevancy && _vm.item.islink && !_vm.taskFold
            ? _c(
                "div",
                { staticClass: "relevancy_wrapper" },
                [
                  _c(
                    "div",
                    { staticClass: "title_group d-flex align-center bbox" },
                    [
                      _c(
                        "div",
                        { staticClass: "name_group d-flex align-center" },
                        [
                          _c("div", {
                            staticClass: "icon cp",
                            class: { close: !_vm.item.switch_relevancy },
                            on: {
                              click: function ($event) {
                                return _vm.swicthRelevancy()
                              },
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "name un_sel cp",
                              on: {
                                click: function ($event) {
                                  return _vm.swicthRelevancy()
                                },
                              },
                            },
                            [_vm._v("关联区")]
                          ),
                          _c("relevancy-add-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.relevancy_add_button,
                                expression: "relevancy_add_button",
                              },
                            ],
                            attrs: { item: _vm.item },
                            on: { handleClick: _vm.clickAdd },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "line" }),
                      _c("div", {
                        staticClass: "delete",
                        on: {
                          click: function ($event) {
                            return _vm.deleteRelevancy(_vm.item)
                          },
                        },
                      }),
                    ]
                  ),
                  !_vm.relevancy && (_vm.item.switch_relevancy || _vm.sell)
                    ? _c("widget-container", {
                        ref: "container",
                        staticClass: "relevancy_container",
                        attrs: {
                          relevancy: true,
                          list: _vm.item.controlLinkList,
                          parent: _vm.item,
                          editable: _vm.editable,
                          recycle: _vm.recycle,
                          system: _vm.system,
                          public_notice: _vm.public_notice,
                          shopping_mail: _vm.shopping_mail,
                        },
                        on: {
                          handleDeleteWidget: _vm.deleteWidget,
                          handleMofifiedSign: _vm.modifiedSign,
                          handleSortFile: _vm.handleSortFile,
                          setZIndex: _vm.setZIndex,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "relevancy_hint bbox" },
                                    [
                                      _vm._v(
                                        "点击上方加号或拖拽右侧控件至此进行添加"
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2585535959
                        ),
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.fun_group_status
            ? _c(
                "div",
                {
                  staticClass: "fun_group d-flex align-center justify-end bbox",
                },
                [
                  _vm.projection_screen_status
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "button projection_screen",
                            class: { active: _vm.projection_screen_start },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.doProjectionScreen()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.projection_screen_start ? "结束" : "开始"
                                ) +
                                "投屏\n        "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.lock_status
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "button btn_dark",
                            on: {
                              click: function ($event) {
                                return _vm.doSendWidget()
                              },
                            },
                          },
                          [_vm._v("完成")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "button btn_light",
                            on: {
                              click: function ($event) {
                                return _vm.doRepeal()
                              },
                            },
                          },
                          [_vm._v("撤销")]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.expand_status
        ? _c("expand-bar", {
            attrs: { remindList: _vm.item.remindList, recycle: _vm.recycle },
            on: { handleOpenReminder: _vm.handleOpenReminder },
          })
        : _vm._e(),
      _vm.item.switch_reminder
        ? _c("reminder", {
            attrs: {
              data: _vm.reminder_data,
              list: _vm.reminder_list,
              type: _vm.reminder_type,
              conId: _vm.item.id || null,
              taskId: _vm.$store.state.task_config.task_id || null,
              top: true,
            },
            on: {
              handleClose: _vm.closeReminder,
              handleComplete: _vm.reminderComplete,
              handleDelete: _vm.deleteReminder,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }