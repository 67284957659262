<template>
  <div
    class="components_task_main_widget_content_text_page"
  > 
   <!-- :autosize="{minRows: 1, maxRows: 15}" -->
    <el-input
      :key="resizeKey"
      v-model="text"
      type="textarea"
      :autosize="true"
      placeholder="请输入文本内容"
      class="text"
      :class="{disabled: !editable}"
      :disabled="!editable"
      :readonly="other_lock"
      resize="none"
      @input="changeInput"
      @click.native.stop="$emit('handleLock', item)"
      @select.native.stop="$emit('handleLock', item)"
    ></el-input>
  </div>
</template>
<script>
import store from '@/store'

export default {
  props:{
    item:{
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: true,
    },
    other_lock: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Object,
      default: null,
    },
  },
  computed: {
    task () {
      return store.state.task
    },
    clearWidgetContent () {
      try {
        const { task } = this
        return task.task.clearWidgetContent
      } catch (e) {
        return false
      }
    }
  },
  watch:{
    'item.repeal_content': {
      handler: function (val) {
        if (val) {
          this.init()
          this.$set(this.item, 'repeal_content', false)
        }
      },
      deep: true
    },
    clearWidgetContent: function (val) {
      if (val) {
        this.text = ''
        this.changeInput()
      }
    }
  },
  data(){
    return {
      text: '',
      resizeKey: 0
    }
  },
  mounted () {
    this.init()
    window.addEventListener('resize', this.forceUpdate)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.forceUpdate)
  },
  methods: {
    /**
     * 页面尺寸变化时 强制更新组件
     */
    forceUpdate() {
      this.resizeKey ++
    },
    changeInput(){
      this.$set(this.item, 'content', this.text);
      this.$api.moduleTask.signWidgetModified(this.item);
      this.modifiedSign();
    },
    init(){
      const {item} = this;
      this.text=item.content;
    },
    modifiedSign(){
      this.$emit('handleMofifiedSign', {item: this.item, parent: this.parent});
    },
  }

}
</script>
<style lang="scss" scoped>
.components_task_main_widget_content_text_page{
  .text{
    font-size: 14px;
    line-height: 20px;
  }
}

</style>
<style>
.components_task_main_widget_content_text_page .el-textarea__inner{
  border:none!important;
  font-size: 14px;
  line-height: 20px;
  font-family: 'PingFang SC','MicroSoft YaHei';
  padding: 13px 10px;
  background-color: transparent;
}

.components_task_main_widget_content_text_page .el-textarea__inner::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ccc;
}

.components_task_main_widget_content_text_page .el-textarea__inner::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ccc;
}

.components_task_main_widget_content_text_page .el-textarea__inner:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #ccc;
}

.components_task_main_widget_content_text_page .el-textarea__inner.is-disabled::-webkit-input-placeholder {
   /* WebKit browsers */
  color: #ccc;
}

.components_task_main_widget_content_text_page .el-textarea__inner.is-disabled::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ccc;
}

.components_task_main_widget_content_text_page .el-textarea__inner.is-disabled:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #ccc;
}

.components_task_main_widget_content_text_page .el-textarea.is-disabled .el-textarea__inner {
  background-color: #fff;
  color:#333;
}
</style>