import { render, staticRenderFns } from "./Wrapper.vue?vue&type=template&id=073fc964&scoped=true"
import script from "./Wrapper.vue?vue&type=script&lang=js"
export * from "./Wrapper.vue?vue&type=script&lang=js"
import style0 from "./Wrapper.vue?vue&type=style&index=0&id=073fc964&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073fc964",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/pmcs-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('073fc964')) {
      api.createRecord('073fc964', component.options)
    } else {
      api.reload('073fc964', component.options)
    }
    module.hot.accept("./Wrapper.vue?vue&type=template&id=073fc964&scoped=true", function () {
      api.rerender('073fc964', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Task/Main/Widget/Wrapper.vue"
export default component.exports