<template>
<div
  class="c_task_main_widget_content_print_mode_picture bbox"
>
  <el-image
    :key="resizeKey"
    ref="image"
    class="image"
    :src="`${$img_url}${file.url}`"
  >
    <div
      slot="placeholder"
      class="image-slot"
    >
      <img
        class="img_placeholder"
        :src="`${$img_url}${file.url}`"
      >
    </div>
  </el-image>
  <div
    class="filename"
  >
    {{ file.filename }}
  </div>
</div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 0
    },
    fileList: {
      type: Array,
      default: function(){
        return []
      },
    },
    file: {
      type: Object,
      default: null
    },
    preview_list: {
      type: Array,
      default: function(){
        return []
      }
    },
    editable: {
      type: Boolean,
      default: true
    },
    system: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      resizeKey: 0
    }
  },
  mounted () {
    window.addEventListener('resize', this.updateComponent)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateComponent)
  },
  methods: {
    updateComponent() {
      this.resizeKey ++
    }
  }
}
</script>

<style
  scoped
  lang="scss"
>
.c_task_main_widget_content_print_mode_picture{
  width: 100%;
  padding: 5px;
  .image {
    max-width: 100%;
    .image-slot {
      max-width: 100%;
      .img_placeholder {
        width: 100%;
      }
    }
  }
  .filename {
    padding: 5px 0;
    font-size: 14px;
    color: #666;
  }
}
</style>
