var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_task_main_widget_content_text_page" },
    [
      _c("el-input", {
        key: _vm.resizeKey,
        staticClass: "text",
        class: { disabled: !_vm.editable },
        attrs: {
          type: "textarea",
          autosize: true,
          placeholder: "请输入文本内容",
          disabled: !_vm.editable,
          readonly: _vm.other_lock,
          resize: "none",
        },
        on: { input: _vm.changeInput },
        nativeOn: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("handleLock", _vm.item)
          },
          select: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("handleLock", _vm.item)
          },
        },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }