<template>
  <div
    class="components_task_main_widget_wrapper_page bbox"
    :style="{'z-index': index }"
  >
    <div class="contents bbox"
      :style="{'z-index': index }"
      :class="{relevancy, heightlight}"
    >
      <widget-title
        :item="item"
        :relevancy="relevancy"
        :editable="editable"
        :self_lock="self_lock"
        :other_lock="other_lock"
        :parent="parent"
        :system="system"
        :public_notice="public_notice"
        :shopping_mail="shopping_mail"
        :projection_screen_start="projection_screen_start"
        :editable_for_wisdom="projection_screen_status"
        @handleAdd="touchAdd"
        @handleSortFile="handleSortFile"
        @handleCancelAll="cancelAll"
        @handleSelectAll="selectAll"
        @handleMultiDelete="multiDelete"
        @handleMultiDownload="multiDownload"
        @handleLock="handleLock"
        @handleDelete="deleteWidget"
        @handleDeleteRelevancy="deleteRelevancy"
        @handleMofifiedSign="modifiedSign"
        @handleSwitchReminder="handleSwitchReminder"
      ></widget-title>
      <template
        v-if="item && item.type && !taskFold"
      >
        <content-text
          v-if="item.type === 1"
          :item="item"
          :parent="parent"
          :editable="editable"
          :other_lock="other_lock"
          @handleLock="handleLock"
          @handleMofifiedSign="modifiedSign"
        ></content-text>
        <content-number
          v-if="item.type === 2"
          :item="item"
          :parent="parent"
          :editable="editable"
          :other_lock="other_lock"
          @handleLock="handleLock"
          @handleMofifiedSign="modifiedSign"
        ></content-number>
        <content-money
          v-if="item.type === 3"
          :item="item"
          :parent="parent"
          :editable="editable"
          :other_lock="other_lock"
          @handleLock="handleLock"
          @handleMofifiedSign="modifiedSign"
        ></content-money>
        <content-time
          v-if="item.type === 4"
          :item="item"
          :parent="parent"
          :editable="editable"
          :other_lock="other_lock"
          @handleLock="handleLock"
          @handleMofifiedSign="modifiedSign"
        ></content-time>
        <content-file
          v-if="item.type === 6"
          :item="item"
          :parent="parent"
          :editable="editable"
          :self_lock="self_lock"
          :other_lock="other_lock"
          :system="system"
          :shopping_mail="shopping_mail"
          ref="file"
          @handleLock="handleLock"
          @handleMofifiedSign="modifiedSign"
        ></content-file>
        <content-hyperlink
          v-if="item.type === 7"
          :item="item"
          :parent="parent"
          :editable="editable"
          :other_lock="other_lock"
          ref="hyperlink"
          @handleLock="handleLock"
          @handleMofifiedSign="modifiedSign"
        ></content-hyperlink>
        <content-picture
          v-if="item.type === 9"
          :item="item"
          :parent="parent"
          :editable="editable"
          :self_lock="self_lock"
          :other_lock="other_lock"
          :system="system"
          ref="picture"
          @handleLock="handleLock"
          @handleMofifiedSign="modifiedSign"
        ></content-picture>
        <content-contact
          v-if="item.type === 10"
          :item="item"
          :parent="parent"
          :editable="editable"
          :other_lock="other_lock"
          :self_lock="self_lock"
          ref="contact"
          @handleLock="handleLock"
          @handleMofifiedSign="modifiedSign"
          @setZIndex="setZIndex"
        ></content-contact>
        <content-wisdom
          v-if="item.type === 11"
          :item="item"
          :parent="parent"
          :editable="editable"
          :other_lock="other_lock"
          :self_lock="self_lock"
          :status_projection_screen="projection_screen_start"
          ref="wisdom"
          @handleLock="handleLock"
          @handleMofifiedSign="modifiedSign"
          @setZIndex="setZIndex"
        ></content-wisdom>
      </template>
      <!-- 关联区 -->
      <div class="relevancy_wrapper"
        v-if="!relevancy && item.islink && !taskFold"
      >
        <div class="title_group d-flex align-center bbox">
          <div class="name_group d-flex align-center">
            <div class="icon cp"
              :class="{close: !item.switch_relevancy}"
              @click="swicthRelevancy();"
            ></div>
            <div class="name un_sel cp"
              @click="swicthRelevancy();"
            >关联区</div>
            <!-- <div class="add cp"></div> -->
            <relevancy-add-button
              :item="item"
              @handleClick="clickAdd"
              v-show="relevancy_add_button"
            ></relevancy-add-button>
          </div>
          <div class="line"></div>
          <div class="delete"
            @click="deleteRelevancy(item)"
          ></div>
        </div>
        <widget-container
          v-if="!relevancy && (item.switch_relevancy || sell)"
          class="relevancy_container"
          :relevancy="true"
          :list="item.controlLinkList"
          :parent="item"
          :editable="editable"
          :recycle="recycle"
          :system="system"
          :public_notice="public_notice"
          :shopping_mail="shopping_mail"
          @handleDeleteWidget="deleteWidget"
          @handleMofifiedSign="modifiedSign"
          @handleSortFile="handleSortFile"
          ref="container"
          @setZIndex="setZIndex"
        >
          <template
            v-slot
          >
            <div class="relevancy_hint bbox">点击上方加号或拖拽右侧控件至此进行添加</div>
          </template>
        </widget-container>
      </div>
      <div class="fun_group d-flex align-center justify-end bbox"
        v-if="fun_group_status"
      > 
        <template
          v-if="projection_screen_status"
        >
          <div
            class="button projection_screen"
            :class="{active: projection_screen_start}"
            @click.stop="doProjectionScreen()"
          >
            {{ projection_screen_start ? '结束' : '开始' }}投屏
          </div>
        </template>
        <template
          v-if="lock_status"
        >
          <div class="button btn_dark"
            @click="doSendWidget();"
          >完成</div>
          <div class="button btn_light"
            @click="doRepeal();"
          >撤销</div>
        </template>
      </div>
    </div>
    <expand-bar
      v-if="expand_status"
      :remindList="item.remindList"
      :recycle="recycle"
      @handleOpenReminder="handleOpenReminder"
    ></expand-bar>
    <reminder
      v-if="item.switch_reminder"
      :data="reminder_data"
      :list="reminder_list"
      :type="reminder_type"
      :conId="item.id || null"
      :taskId="$store.state.task_config.task_id || null"
      :top="true"
      @handleClose="closeReminder"
      @handleComplete="reminderComplete"
      @handleDelete="deleteReminder"
    ></reminder> 
  </div>
</template>
<script>
import {PTaskDetail} from '@/types'
import WidgetTitle from './Title.vue'
import WidgetContainer from '../WidgetContainer.vue'
import RelevancyAddButton from './Relevancy/AddButton.vue'
import ContentText from './Content/Text.vue'
import ContentNumber from './Content/Number.vue'
import ContentMoney from './Content/Money.vue'
import ContentTime from './Content/Time.vue'
import ContentFile from './Content/File.vue'
import ContentHyperlink from './Content/Hyperlink.vue'
import ContentPicture from './Content/Picture.vue'
import ContentContact from './Content/Contact.vue'
import ContentWisdom from './Content/Wisdom.vue'
import Reminder from '@/components/PopUp/Reminder.vue'
import ExpandBar from './ExpandBar.vue'
import store from '@/store'
export default {
  components: {
    WidgetTitle,
    WidgetContainer,
    RelevancyAddButton,
    ContentText,
    ContentNumber,
    ContentMoney,
    ContentTime,
    ContentFile,
    ContentHyperlink,
    ContentPicture,
    ContentContact,
    ContentWisdom,
    Reminder,
    ExpandBar,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    relevancy: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    parent: {
      type: Object,
      default: null,
    },
    public_notice: {
      type: Boolean,
      default: null,
    },
    recycle: {
      type: Boolean,
      default: false,
    },
    heightlight: {
      type: Boolean,
      default: false,
    },
    system: {
      type: Boolean,
      default: false,
    },
    sell: {
      type: Boolean,
      default: false,
    },
    shopping_mail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    task () {
      const { state } = store
      return state.task
    },
    taskFold() {
      try {
        const { task } = this
        return task.task.taskFold
      } catch (e) {
        return false
      }
    },
    other_lock () {
      let { item } = this
      let { islock, userlock } = item
      let userid = this.$tools.getUserId()
      if (islock && userlock != userid) {
        return true
      }
      return false
    },
    self_lock(){
      let {item} = this;
      let {islock, userlock, deep_level} = item;
      let userid = this.$tools.getUserId();
      // // console.log(islock, userlock, userid);
      if(islock && userlock == userid){
        return true;
      }
      return false;
    },
    is_call(){
      const {task_config} = this.$store.state;
      const {call} = task_config;
      return call;
    },
    fun_group_status(){
      const { lock_status, projection_screen_status } = this
      return lock_status || projection_screen_status
    },
    // 投屏相关状态
    projection_screen_status () {
      const { editable } = this
      // console.log(editable)
      if (!editable) {
        return false
      }
      const { task, task_config } = this.$store.state
      if (!task) {
        return false
      }
      const { call } = task_config
      // console.log(task, task_config, call, editable)
      if (call) {
        return false
      }
      if (task.task.draft) {
        return false
      }
      if (Number(task.task.taskType) === 2) {
        return false
      }
      // const user_id = Number(this.$tools.getUserId())
      const { item } = this
      const { type, id } = item
      if (!id) {
        return false
      }
      // 判断是否创建人
      // if(type == 11){

      //   if(userId){
      //     return Number(userId) === user_id;
      //   }
      // }
      return Number(type) === 11
    },
    // 投屏开始
    projection_screen_start(){
      const {item} = this;
      const {screenList, screenstatus} = item;
      return  screenstatus === 1
      // if(screenList && screenList.length){
        
      //   const has = screenList.find(unit=>{
      //     // return unit.useflag === 1 && (unit.screenTask.taskDetailId === item.id);
      //     return unit.useflag === 1 && unit.screenTask && Boolean(unit.screenTask.taskDetailId === item.id) && unit.screenTask.endFlag === 1;
      //   })
      //   return Boolean(has);
      // }
      // return false;
    },
    // 锁定状态
    lock_status(){
      const {self_lock, is_call } = this;
      const {task_type, draft} = this.$store.state.task_config;
      if(draft == 1){
        return false;
      }
      if(task_type == 2){
        return false;
      }
      return self_lock && !is_call 
    },
    fun_status(){
      const {view} = this.$store.state.task_config;
      const {is_call, self_lock}  = this;
      if(self_lock && !is_call && !view){
        return true;
      }
      return false;
    },
    expand_status(){
      const {item} = this;
      const {remindList} = item;
      if(remindList && remindList.length ){
        return true;
      }
      return false;
    },
    reminder_type(){
      const {item} = this;
      const {remindList} = item;
      if(remindList && remindList.length > 1){
        return 'list';
      }
      return 'detail';
    },
    // reminder_data(){
    //   const {item} = this;
    //   const {remindList} = item;
    //   if(remindList.length == 1){
    //     return remindList[0];
    //   }
    //   return {};
    // },
    reminder_list(){
      const {item} = this;
      const {remindList} = item;
      return remindList;
    },
    relevancy_add_button(){
      const {editable} = this;
      return editable;
    }
  },
  data(){
    return{
      reminder_data: {},
      index: 3
    }
  },
  methods: {
    async swicthRelevancy(){
      const {item} = this;
      const {islink, controlLinkList, id} = item;
      if(islink && controlLinkList === null){
        // // console.log('加载关联')
        const res = await this.$api.moduleTask.getRelevancy({id});
        if(res.message === 'success' && res.contents.controlLinkList){
          this.$set(item, 'controlLinkList', res.contents.controlLinkList);
        }
        // 将关联数据添加到原始数据中
        const {task_original} = this.$store.state;
        const {taskDeatails} = task_original;
        const index = taskDeatails.findIndex(unit=>{
          return unit.id === id;
        })
        if(index != -1){
          taskDeatails[index].controlLinkList = (this.$tools.deepClone(res.contents.controlLinkList));
        }
      }
      this.$set(this.item, 'switch_relevancy', !this.item.switch_relevancy);
    },
    touchAdd() {
      const { item } = this
      if (item.type === 6) {
        this.$refs.file.touchAdd()
      }
      if (item.type === 7) {
        this.$refs.hyperlink.touchAdd()
      }
      if (item.type === 9) {
        this.$refs.picture.touchAdd()
      }
      if (item.type === 10) {
        this.$refs.contact.touchAdd()
      }
    },
    cancelAll(){
      const {item} = this;
      if(item.type === 6){
        this.$refs.file.cancelAll();
      }
      if(item.type === 9){
        this.$refs.picture.cancelAll();
      }
    },
    selectAll(){
      const {item} = this;
      if(item.type === 6){
        this.$refs.file.selectAll();
      }
      if(item.type === 9){
        this.$refs.picture.selectAll();
      }
    },
    multiDelete(){
      const {item} = this;
      if(item.type === 6){
        this.$refs.file.multiDelete();
      }
      if(item.type === 9){
        this.$refs.picture.multiDelete();
      }
    },
    multiDownload(){
      const {item} = this;
      if(item.type === 6){
        this.$refs.file.multiDownload();
      }
      if(item.type === 9){
        this.$refs.picture.multiDownload();
      }
    },
    handleLock (item) {
      // console.log('lock')
      const { editable, public_notice, system } = this
      console.log({
        public_notice
      })
      if (!editable || public_notice || system) {
        return
      }
      if (this.$store.state.task_config.view) {
        return
      }
      this.$api.moduleTask.getLock(item)
    },
    doRepeal(){
      const {item, parent} = this;
      this.$emit('handleRepeal', {item, parent});
    },
    async doSendWidget(){
      const {item,parent} = this;
      const res = await this.$api.moduleTask.sendWidget(item, parent);
      return res;
    },
    deleteWidget(data){
      // this.$emit('handleDelete', {widget: data, parent: this.parent});
      this.$emit('handleDelete',data);
    },
    deleteRelevancy(data){
      this.$emit('handleDeleteRelevancy', data);
    },
    async clickAdd(){
      const {item} = this;
      const {islink, controlLinkList, id} = item;
      if(islink && controlLinkList === null){
        // // console.log('加载关联')
        const res = await this.$api.moduleTask.getRelevancy({id});
        if(res.message === 'success' && res.contents.controlLinkList){
          this.$set(item, 'controlLinkList', res.contents.controlLinkList);
        }
        // 将关联数据添加到原始数据中
        const {task_original} = this.$store.state;
        const {taskDeatails} = task_original;
        const index = taskDeatails.findIndex(unit=>{
          return unit.id === id;
        })
        if(index != -1){
          taskDeatails[index].controlLinkList = (this.$tools.deepClone(res.contents.controlLinkList));
        }
      }
      this.$set(this.item, 'switch_relevancy', true);
    },
    modifiedSign(params){
      this.$emit('handleMofifiedSign', params);
    },
    scrollToWidget(widget_id){
      // console.log(widget_id);
      if(this.$refs.container){
        this.$refs.container.scrollToWidget(widget_id);
      }else{
        // this.scrollToWidget(widget_id);
        // 关联无容器时关闭
        this.$hideLoading();

      }
    },
    handleSwitchReminder(item){
     
      // console.log('switch_reminder')
     
      const {remindList} = this.item;
      if(remindList.length == 1){
        this.$set(this, 'reminder_data', remindList[0]);
        // return remindList[0];
      }else{
        this.$set(this, 'reminder_data', {});
      }
      this.$set(item, 'switch_reminder', true);
     
      
    },
    closeReminder(){
      this.$set(this.item, 'switch_reminder', false);
    },
    handleOpenReminder(){
      this.handleSwitchReminder(this.item);
    },

    reminderComplete(remind){
      // console.log(remind, 'remind');
      const {item} = this;
      if(!item.remindList){
        this.$set(item, 'remindList', []);
      }
      const {remindList, reminder_custom_index} = item;
      const {id, custom_index,} = remind;
      
      if(!id && !custom_index){
        // 新建控件的提醒
        if(!reminder_custom_index){
          this.$set(item, 'reminder_custom_index', remindList.length);
        }
        remindList.push(Object.assign({}, remind, {custom_index: item.reminder_custom_index++}));
      }
      if(id){
        const index = remindList.findIndex(unit=>{
          return unit.id == id;
        })
        if(index == -1){
          // 新增
          remindList.push(remind);
        }else{
          this.$set(item.remindList, index, remind);
        }
      }
      if(custom_index && !id){
        const index = remindList.findIndex(unit=>{
          return unit.custom_index == custom_index;
        })
        if(index != -1){
          this.$set(item.remindList, index, remind);
        }
      }
    },
    async deleteReminder(remind){
      const {item} = this;
      if(!item.remindList){
        this.$set(item, 'remindList', []);
      }

  
      const {remindList} = item;
      const {id, custom_index,} = remind;
      const index = remindList.findIndex(unit=>{
        if(id){
          return unit.id == id;
        }
        if((custom_index != '' && custom_index != null) && !id){
          return unit.custom_index == custom_index;
        }
      })
      if(id){
        const res = await this.$api.moduleTask.deleteReminder(remind);
        if(res.message !== 'success'){
          return;
        }
      }
      if(index!== -1){
        remindList.splice(index, 1);
        this.$notice({desc: '删除成功'});
      }

        


     
    },
    setZIndex(zindex){
      const {parent} = this;
      if(parent){
        this.$emit('setZIndex', zindex);
      }else{
        this.index = zindex;
      }
    },
    handleSortFile(params){
      this.$emit('handleSortFile', params);
    },
    async doProjectionScreen(){
      const membership_privileges = this.$api.user.membershipPrivileges({
        hint: '“智慧桌”控件是“智慧项目管理实训桌”硬件设备的配套控件，使老师能够在课堂上带领学生进行实践任务的布置、演练与计时，此控件仅限VIP会员使用。'
      });
      if(!membership_privileges){
        return false
      }
      const {item, projection_screen_start} = this;
      if(projection_screen_start){
        this.$openConfirm({
          title:'提示',
          desc:'是否确定结束投屏?',
          sure:'是',
          sureType: '', // 确定按钮样式 '','warn'
          cancel:'否',
          onSure: async ()=>{
            await this.item.endForScreen();
          },
          onCancel:()=>{},
        })
        // const res = await item.endForScreen();
        // // console.log(res);
      }else{
        const res = await this.doSendWidget();
        if(res.message === 'success'){
  
          item.projectionScreen();
        }
      }
    }
  }

}
/**
控件示例
item: {
  open_relevancy: 是否开启关联功能
  switch_relevancy: 展开收起关联
}

 */
</script>
<style lang="scss" scoped>
.components_task_main_widget_wrapper_page .contents{
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #F0F0F0;
  // padding-bottom: 10px;
  // padding: 10px 0;
  position: relative;
  z-index: 3;
  word-break : normal; 
  &.heightlight{
    border: 3px solid #FFD4A0;
  }
  &.relevancy{
    background: #F4F7FC;
  }
  .text{
    textarea{
      border:none!important;
    }
  }
  .relevancy_wrapper{
    .title_group{
      width: 100%;
      padding: 13px 10px;
      .name_group{
        .icon{
          @include background(10px, 11px);
          width: 11px;
          height: 11px;
          background-image: url(~@/assets/images/task/relevancy_open.png);
          &.close{
            @include background(10px, 9px);
            background-image: url(~@/assets/images/task/relevancy_close.png);
          }
        }
        .name{
          padding: 0 5px;
          font-size: 14px;
          color: #555555;
          line-height: 19px;
        }
      
      }
      .line{
        flex: 1;
        border-bottom: 1px dashed #d8d8d8;
        margin: 0 10px;
      }
      .delete{
        @include background;
        width: 26px;
        height: 26px;
        background-image: url(~@/assets/images/task/relevancy_delete.png);
      }
    }
  }
  .relevancy_container{
    padding: 0 10px 10px;
  }
  .relevancy_hint{
    height: 40px;
    background: #F4F7FC;
    border-radius: 4px;
    font-size: 12px;
    color: #88A0B5;
    line-height: 40px;
    width: 100%;
    padding: 0 10px;
  }
  .fun_group{
    padding: 0 10px 7px;
    .button{
      @include transition;
      line-height: 26px;
      font-size: 12px;
      padding: 0 12px;
      margin-left: 10px;
      border-radius: 4px;
      box-sizing: border-box;
      &.projection_screen{
        border: 1px solid $main_blue;
        line-height: 24px;
        color: $main_blue;
        &.active{
          border-color: #C84B33;
          color: #C84B33;
        }
      }
    }
  }
}

</style>
