<template>
  <el-date-picker
    v-model="date"
    :editable="false"
    :clearable="editable && !other_lock"
    type="date"
    placeholder="选择日期"
    class="components_task_main_widget_content_part_time_for_date_page cp"
    popper-class="components_task_main_widget_content_part_time_for_date_page_popover"
    ref="date"
    @change="changeDate();"
    :default-value="new Date"
    :disabled="!editable"
    :readonly="other_lock"
    @click.native.stop="handleClick();"
  >
  </el-date-picker>    
</template>
<script>
import store from '@/store'

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    content: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    other_lock: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    task () {
      return store.state.task
    },
    clearWidgetContent () {
      try {
        const { task } = this
        return task.task.clearWidgetContent
      } catch (e) {
        return false
      }
    }
  },
  data(){
    return {
      date: '',
    }
  },
  watch:{
    'item.repeal_title': {
      handler: function(val){
        if(val){
          this.init();
          this.$set(this.item, 'repeal_title', false);
        }
      },
      deep: true,
    },
    clearWidgetContent: function (val) {
      if (val) {
        this.date = ''
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      if (this.content != '') {
        this.date = Number(this.content)
      }
    },
    async changeDate () {
      // console.log(this.date);
      if (this.date == null) {
        const { task_config } = this.$store.state
        const { edit } = task_config
        const { item } = this
        const { id } = item
        if (edit) {
          if (id) {
            const res = await this.$api.moduleTask.getLock(item)
            if (!res) {
              this.init()
              return
            }
          }
        }
        this.$emit('handleChange', {year : undefined,month : undefined, date: undefined})
        this.date=''
        return
      }
      const time = new Date(this.date)
      const year = time.getFullYear()
      const month = time.getMonth()
      const date = time.getDate()
      this.$emit('handleChange', {year,month,date})
    },
    async handleClick () {
      const { task_config } = this.$store.state
      const { edit } = task_config
      const { item } = this
      const { id } = item
      if (edit) {
        if (id) {
          const res = await this.$api.moduleTask.getLock(item)
          if (!res) {
            this.$refs.date.handleClose()
            return
          }
        }
      }
      this.$utils.setPopoverZIndex('.components_task_main_widget_content_part_time_for_date_page_popover')
    }
  }
}
</script>
<style lang="scss">
.components_task_main_widget_content_part_time_for_date_page{
  width: 160px!important;
  border: none;
  .el-input__inner{
    border: none;
    background-color: transparent;
  }
}
</style>
<style lang="css">
/* 时间选择样式修改  */
.el-date-table td span{
  border-radius: 0;
}
.el-input__icon {
  line-height: unset;
}
.el-input__inner{
  height: auto;
  cursor: pointer;
}
.el-input.is-disabled .el-input__inner{
  background-color: transparent;
}

</style>

