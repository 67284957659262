<!--
 * @Author       : Hugo
 * @Date         : 2022-03-30 15:02:22
 * @LastEditTime : 2022-03-31 11:28:49
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Task/Main/Widget/Content/Part/Screen.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_task_main_widget_content_part_screen d-flex align-center justify-start"
    :class="{warn: warnScreen, active: status_projection_screen}"
    @click.stop="touchSingleScreen(unit)"
  >
    <div class="status mr-2"></div>
    <div class="name">{{unit.screenName}}</div>
    <div class="symbol"
      v-show="show_symbol"
    >,</div>
  </div>
</template>

<script>
export default {
  props: {
    unit: {
      type: Object,
      default: undefined
    },
    item: {
      type: Object,
      default: undefined
    },
    show_symbol: {
      type: Boolean,
      default: true,
    },
    status_projection_screen: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    warnScreen(){
      const {item, unit} = this;
      const {id} = item;
      const {screenTask, useflag} = unit;
      if(!screenTask){
        return true
      }
      const {taskDetailId} = screenTask;
      if(taskDetailId == id){
        return false // 成功投屏
      }
      return true // 未成功投屏
    }
  },
  methods: {
    touchSingleScreen(){
      this.$emit('handleTouchSingleScreen', this.unit)
    }
  }

}
</script>

<style lang="scss" scoped>
.c_task_main_widget_content_part_screen{
  color: #333;
  &.active{
    .status{
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-image: linear-gradient(180deg, #00B322 0%, #007C0F 100%);
    }
    .name{
      color: #007269;
      margin-right: 6px;
    }
    &.warn{
      .status{
        background-image: none;
        background-color: #C84B33;
      }
      .name{
        color: #C84B33;
      }
    }
  }
}
</style>
