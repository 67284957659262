var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_main_widget_content_wisdom_page bbox d-flex align-start justify-start",
    },
    [
      _vm.status_projection_screen && _vm.editable
        ? _c(
            "div",
            {
              staticClass:
                "projection_screen_group separate un_sel flex-shrink-0",
            },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom-start",
                    trigger: "click",
                    "visible-arrow": false,
                    "popper-class":
                      "components_task_main_widget_content_wisdom_page_projection_screen",
                  },
                  model: {
                    value: _vm.switch_projection,
                    callback: function ($$v) {
                      _vm.switch_projection = $$v
                    },
                    expression: "switch_projection",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "button btn",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.endForScreen()
                        },
                      },
                    },
                    [_vm._v("结束投屏")]
                  ),
                  _c("template", { slot: "reference" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "projection_screen d-flex align-center justify-start cp",
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: _vm.item.getScreenUserData().userName,
                              placement: "bottom",
                              "open-delay": 500,
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src: `${_vm.$avatar_url}${
                                  _vm.item.getScreenUserData().thumbnail
                                }@!small200`,
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.checkUser()
                                },
                              },
                            }),
                          ]
                        ),
                        _c("div", { staticClass: "text ml-8" }, [
                          _vm._v("投屏中"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "content_group d-flex align-center justify-start separate",
        },
        [
          _c("img", {
            staticClass: "icon mr-8",
            attrs: {
              src: require("@/assets/images/task/wisdom/content.png"),
              alt: "",
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.content,
                expression: "content",
              },
            ],
            staticClass: "content",
            attrs: {
              type: "text",
              placeholder: "请输入投屏的任务描述",
              readonly: !_vm.editable || _vm.status_projection_screen,
            },
            domProps: { value: _vm.content },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.content = $event.target.value
                },
                function ($event) {
                  _vm.item.edit({ key: "content", value: _vm.content })
                  _vm.modifiedSign()
                },
              ],
              click: function ($event) {
                $event.stopPropagation()
                return _vm.getLock()
              },
              select: function ($event) {
                $event.stopPropagation()
                return _vm.getLock()
              },
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "time_group d-flex align-center justify-start separate",
        },
        [
          _c("img", {
            staticClass: "icon mr-8",
            attrs: {
              src: require("@/assets/images/task/wisdom/time.png"),
              alt: "",
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.days,
                expression: "days",
              },
            ],
            staticClass: "days",
            attrs: {
              type: "text",
              maxlength: "2",
              placeholder: _vm.time_placeholder,
              readonly: !_vm.editable || _vm.status_projection_screen,
            },
            domProps: { value: _vm.days },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.days = $event.target.value
                },
                function ($event) {
                  return _vm.numerInput({ type: "days" })
                },
              ],
              select: function ($event) {
                $event.stopPropagation()
                return _vm.getLock()
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.getLock()
              },
            },
          }),
          _c("div", { staticClass: "text" }, [_vm._v("天")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.hours,
                expression: "hours",
              },
            ],
            staticClass: "hours",
            attrs: {
              type: "text",
              maxlength: "2",
              placeholder: _vm.time_placeholder,
              readonly: !_vm.editable || _vm.status_projection_screen,
            },
            domProps: { value: _vm.hours },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.hours = $event.target.value
                },
                function ($event) {
                  return _vm.numerInput({ type: "hours" })
                },
              ],
              select: function ($event) {
                $event.stopPropagation()
                return _vm.getLock()
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.getLock()
              },
            },
          }),
          _c("div", { staticClass: "text" }, [_vm._v("时")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.minutes,
                expression: "minutes",
              },
            ],
            staticClass: "minutes",
            attrs: {
              type: "text",
              maxlength: "2",
              placeholder: _vm.time_placeholder,
              readonly: !_vm.editable || _vm.status_projection_screen,
            },
            domProps: { value: _vm.minutes },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.minutes = $event.target.value
                },
                function ($event) {
                  return _vm.numerInput({ type: "minutes" })
                },
              ],
              select: function ($event) {
                $event.stopPropagation()
                return _vm.getLock()
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.getLock()
              },
            },
          }),
          _c("div", { staticClass: "text" }, [_vm._v("分")]),
        ]
      ),
      _c(
        "div",
        { staticClass: "select_group" },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-start",
                trigger: "click",
                "visible-arrow": false,
                disabled: !_vm.editable || _vm.status_projection_screen,
                "popper-class":
                  "components_task_main_widget_content_wisdom_page_select_group",
              },
              model: {
                value: _vm.switch_screen,
                callback: function ($$v) {
                  _vm.switch_screen = $$v
                },
                expression: "switch_screen",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "list_group d-flex align-start justify-start flex-column un_sel",
                },
                [
                  _vm.screen_list && _vm.screen_list.length
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "all flex-shrink-0 d-flex align-center justify-start bbox cp",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleSelectAll()
                              },
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "icon flex-shrink-0",
                              class: { active: _vm.selected_all },
                            }),
                            _c("div", { staticClass: "text" }, [
                              _vm._v("全选"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "list_box" },
                          [
                            _vm._l(_vm.screen_list, function (item, index) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "unit d-flex align-center justify-start bbox cp",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleSelect(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "icon flex-shrink-0",
                                      class: { active: item.cf_selected },
                                    }),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(_vm._s(item.screenName)),
                                    ]),
                                  ]
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]
                    : _c(
                        "div",
                        {
                          staticClass: "unit none",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.switch_screen = false
                            },
                          },
                        },
                        [_vm._v("无可用设备")]
                      ),
                ],
                2
              ),
              _c("template", { slot: "reference" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "select_btn_group d-flex align-start justify-start cp",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.doNothing()
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "icon mr-8 mt-5",
                      attrs: {
                        src: require("@/assets/images/task/wisdom/select.png"),
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.touchSelect()
                        },
                      },
                    }),
                    _vm.item.screenList && _vm.item.screenList.length
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "selected_group d-flex alian-center justify-start flex-wrap",
                            },
                            [
                              _vm._l(
                                _vm.item.screenList,
                                function (unit, index) {
                                  return [
                                    _c("wisdom-screen", {
                                      key: index,
                                      attrs: {
                                        unit: unit,
                                        item: _vm.item,
                                        status_projection_screen:
                                          _vm.status_projection_screen,
                                        show_symbol:
                                          index !=
                                            _vm.item.screenList.length - 1 &&
                                          !_vm.status_projection_screen,
                                      },
                                      on: {
                                        handleTouchSingleScreen:
                                          _vm.touchSingleScreen,
                                      },
                                    }),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      : _c(
                          "div",
                          {
                            staticClass: "text",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.touchSelect()
                              },
                            },
                          },
                          [_vm._v("选择投屏设备")]
                        ),
                    0
                      ? _c(
                          "div",
                          {
                            staticClass: "text",
                            class: { active: _vm.screen_name !== "" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.screen_name === ""
                                  ? "选择投屏设备"
                                  : _vm.screen_name
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }