<template>
  <div
    class="components_task_main_widget_content_money_page bbox d-flex align-center"
  >
    <input type="text" class="money bbox"
      :class="{disabled: !editable}"
      :disabled="!editable"
      :readonly="other_lock"
      placeholder="输入金额"
      v-model="content"
      @input="changeContent()"
      @click.stop="$emit('handleLock', item)"
      @select.stop="$emit('handleLock', item)"
    >
    <monetary-unit
      class="bbox unit_group"
      @handleChange="changeUnit"
      :item="item"
      :unit="item.unit"
      :editable="editable"
      :other_lock="other_lock"
      @handleLock="handleLock"
    ></monetary-unit>
  </div>
</template>
<script>
import MonetaryUnit from './Part/MonetaryUnit.vue';
import store from '@/store'
export default {
  components: {
    MonetaryUnit,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    other_lock: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Object,
      default: null,
    },
  },
  computed: {
    task () {
      return store.state.task
    },
    clearWidgetContent () {
      try {
        const { task } = this
        return task.task.clearWidgetContent
      } catch (e) {
        return false
      }
    }
  },
  data(){
    return {
      content: '',
    }
  },
  watch:{
    'item.repeal_content': {
      handler: function(val){
        if(val){
          this.init();
          this.$set(this.item, 'repeal_content', false);
        }
      },
      deep: true,
    },
    clearWidgetContent: function (val) {
      if (val) {
        this.content = ''
        this.changeContent()
      }
    }
  },
  methods: {
    changeContent(){
      const {content} = this;
      // 只能输入数字和[.]
      const str = content
        .replace(/[^\d^\.]+/g, "")
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      if(str != content){
        this.content = str;
      }
      this.$set(this.item, 'content', this.content);
      this.$api.moduleTask.signWidgetModified(this.item);
      this.modifiedSign();
    },
    changeUnit(unit){
      this.$set(this.item, 'unit', unit);
      this.$api.moduleTask.signWidgetModified(this.item);
      this.modifiedSign();
    },
    handleLock(item){
      this.$emit('handleLock', item);
    },
    init(){
      const {item} = this;
      if(item.content){
        this.content = item.content;
      }
    },
    modifiedSign(){
      this.$emit('handleMofifiedSign', {item: this.item, parent: this.parent});
    },
  },
  mounted(){
    this.init();
  }
}
</script>
<style lang="scss" scoped>
.components_task_main_widget_content_money_page{
  height: 47px;
  padding: 0 10px;
  input{
    @include placeholder(#ccc);
    border: none;
    outline: none;
    font-size: 14px;
    color: #333;
    line-height: 24px;
    background-color: transparent;
  }
  .money{
    width: 180px;
    border-right: 1px solid #f4f4f4;
  }
  .unit_group{
    padding-left: 10px;
  }
}
</style>