var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_task_main_widget_content_hyperlink_page Bbox" },
    [
      _vm._l(_vm.list, function (val) {
        return [
          _c(
            "div",
            { key: val.index, staticClass: "val d-flex align-center" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: val.name,
                    expression: "val.name",
                  },
                ],
                staticClass: "name bbox",
                class: { disabled: !_vm.editable },
                attrs: {
                  type: "text",
                  disabled: !_vm.editable,
                  readonly: _vm.other_lock,
                  placeholder: "请输入链接说明",
                },
                domProps: { value: val.name },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(val, "name", $event.target.value)
                    },
                    function ($event) {
                      return _vm.changeContent()
                    },
                  ],
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$emit("handleLock", _vm.item)
                  },
                  select: function ($event) {
                    $event.stopPropagation()
                    return _vm.$emit("handleLock", _vm.item)
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "url_group bbox", class: { show: val.show } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: val.url,
                        expression: "val.url",
                      },
                    ],
                    ref: `url_${val.index}`,
                    refInFor: true,
                    staticClass: "url bbox",
                    class: { edit: val.edit, on: val.url },
                    attrs: {
                      type: "text",
                      placeholder: "请输入链接地址",
                      disabled: !val.edit && val.url !== "",
                    },
                    domProps: { value: val.url },
                    on: {
                      click: function ($event) {
                        return _vm.touchUrl(val)
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.doneEdit(val)
                      },
                      blur: function ($event) {
                        return _vm.doneEdit(val)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(val, "url", $event.target.value)
                      },
                    },
                  }),
                  val.edit && 0
                    ? _c(
                        "div",
                        {
                          staticClass: "done cp",
                          on: {
                            click: function ($event) {
                              return _vm.doneEdit(val)
                            },
                          },
                        },
                        [_vm._v("完成")]
                      )
                    : _vm._e(),
                  val.url !== "" && !val.edit && 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "edit_group d-flex align-center justify-space-between",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center big" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "button go",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToUrl(val.url)
                                    },
                                  },
                                },
                                [_vm._v("点击前往")]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "d-flex align-center" }, [
                            _vm.editable
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.startEdit(val)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyUrl(val.url)
                                      },
                                    },
                                  },
                                  [_vm._v("复制")]
                                )
                              : _vm._e(),
                            _vm.editable
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteItem(val)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              val.edit
                ? _c("div", { staticClass: "button btn_dark" }, [
                    _vm._v("\n        完成\n      "),
                  ])
                : _vm._e(),
              !val.edit
                ? [
                    val.url !== ""
                      ? _c(
                          "div",
                          {
                            staticClass: "button btn_dark transition",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.goToUrl(val.url)
                              },
                            },
                          },
                          [_vm._v("\n          前往\n        ")]
                        )
                      : _vm._e(),
                    _vm.editable
                      ? _c(
                          "div",
                          {
                            staticClass: "button btn_dark transition",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.startEdit(val)
                              },
                            },
                          },
                          [_vm._v("\n          编辑\n        ")]
                        )
                      : _vm._e(),
                    _vm.editable
                      ? _c(
                          "div",
                          {
                            staticClass: "button btn_dark transition",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.deleteItem(val)
                              },
                            },
                          },
                          [_vm._v("\n          删除\n        ")]
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }