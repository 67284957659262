<template>
  <div class="components_task_main_widget_title_fun_group_page fun_group flex-shrink-0">
    <el-popover
      v-if="main_switch"
      v-model="switch_menu"
      placement="bottom-end"
      trigger="click"
      :visible-arrow="false"
      popper-class="components_task_main_widget_title_fun_group_page_menu"
    >
      <div
        class="menu_group"
      >
        <template
          v-if="relevancy_status"
        >
          <div
            v-if="can_relevancy && !item.islink"
            class="button"
            @click="openRelevancy"
          >
            关联
          </div>
          <div
            v-if="can_relevancy && item.islink"
            class="button"
            @click="deleteRelevancy"
          >
            删除关联
          </div>
        </template>
        <div
          v-if="1"
          class="button"
          @click.stop="doCopy"
        >
          复制
        </div>
        <div
          v-if="delete_right"
          class="button"
          @click="doDelete"
        >
          删除
        </div>
        <div
          v-if="reminder_status && !task_config.view"
          class="button"
          @click="setReminder"
        >
          设置提醒
        </div>
      </div>
      <template slot="reference">
        <div
          class="menu button"
          @click="handleClick"
        ></div>
      </template>
    </el-popover>
  </div>    
</template>
<script>
import { PTaskDetail } from '@/types'
import store from '@/store'
import tools from '@/api/tools'
export default {
  data(){
    return{
      switch_menu: false,
    }
  },
  props:{
    item: {
      type: Object,
      default: null
    },
    relevancy: {
      type: Boolean,
      default: false
    },
    public_notice: {
      type: Boolean,
      default: null
    },
    system: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    task_config () {
      return this.$store.state.task_config
    },
    /**
     * 总开关
     */
    main_switch () {
      const { relevancy, delete_right } = this
      return !(relevancy && !delete_right)

    },
    /**
     * 能否关联 , 已经是关联则不能关联
     */
    can_relevancy () {
      const { relevancy } = this
      return !relevancy

    },
    relevancy_status () {
      const { public_notice } = this
      return !public_notice

    },
    reminder_status () {
      const { task_config } = this.$store.state
      const { relevancy, public_notice, system } = this
      const { task_type, draft } = task_config
      if (system) {
        return false
      }
      if (Number(task_type) === 2) {
        return false
      }
      if (draft) {
        return false
      }
      if (public_notice) {
        return false
      }
      return !relevancy
    },
    delete_right () {
      const { item } = this
      const { userId, type, id } = item
      const user_id = Number(this.$tools.getUserId())
      return true
    }
  },
  methods: {
    /**
     * 复制控件
     */
    doCopy () {
      let copyUnit = new PTaskDetail(Object.assign(
        {},
        this.item
      ))
      // 检测是否已添加
      if (store.state.copyWidgetList && store.state.copyWidgetList.length) {
        const aimIndex = store.state.copyWidgetList.findIndex((unit) => {
          return unit.id === this.item.id
        })
        if (aimIndex !== -1) {
          copyUnit = store.state.copyWidgetList[aimIndex]
          // 将其移到最新位置
          store.state.copyWidgetList.splice(aimIndex, 1)
        }
      }
      this.$notice({
        desc: '复制成功'
      })
      this.clearFileList(copyUnit)
      store.state.copyWidgetList.unshift(copyUnit)
      tools.setCookie(
        'copyWidgetData',
        {
          userId: tools.getUserId(),
          list: store.state.copyWidgetList
        }
      )
      this.switch_menu = false
    },
    /**
     * 将文件列表无用信息清除【复制控件时】
     */
    clearFileList (pTaskDetail) {
      const { fileList } = pTaskDetail
      if (fileList && fileList.length) {
        fileList.forEach((unit) => {
          delete unit.id
          delete unit.conId
          delete unit.taskId
          delete unit.userId
          delete unit.userName
        })
      }
    },
    openRelevancy () {
      this.$set(this.item, 'islink', true)
      this.$set(this.item, 'switch_relevancy', true)
      this.$set(this.item, 'controlLinkList', [])
      this.switch_menu = false
    },
    deleteRelevancy () {
      this.$emit('handleDeleteRelevancy', this.item)
      this.switch_menu = false
    },
    async handleClick () {
      this.setIndex()
      const { task_config } = this.$store.state
      const { edit } = task_config
      const { item, public_notice } = this
      const { id } = item
      if (public_notice) {
        return
      }
      if (edit) {
        if (id) {
          const res = await this.$api.moduleTask.getLock(item)
          if (!res) {
            this.switch_menu = false
          }
        }
      }
    },
    setIndex(){
      this.$utils.setPopoverZIndex('.components_task_main_widget_title_fun_group_page_menu');
    },
    doDelete(){
      this.$emit('handleDelete', this.item);
    },
    setReminder(){
      this.switch_menu = false;
      this.$emit('handleSwitchReminder', this.item);
    },
  }
}
</script>
<style lang="scss" scoped>
.components_task_main_widget_title_fun_group_page.fun_group{
  height: 47px;
  .menu{
    @include transition;
    @include background(5px, 20px);
    width: 30px;
    height: 100%;
    background-image: url(~@/assets/images/task/title_menu.png);
    border-top-right-radius: 4px;
    &:hover{
      background-color: #eee;
    }
  }
}


</style>
<style lang="scss">
.components_task_main_widget_title_fun_group_page_menu{
  padding: 5px 0;
  margin-top: 0!important;
  .menu_group{
    .button{
      @include transition;
      @include bbox;
      padding-left: 16px;
      background-color:#fff;
      font-size: 14px;
      color: #333333;
      line-height: 34px;
      &:hover{
        background-color: #eee;
      }
    }
  }
}
</style>